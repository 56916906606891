export const textColors = {
  statusOk: {
    color: '#218626',
  },
  statusWarn: {
    color: '#867321',
  },
  statusError: {
    color: '#862421',
  },
}
